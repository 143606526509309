@import url(https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
:root{
    --primary-white: rgb(255, 255, 255);
    --primary-orange: #f9ab00;
    --primary-lightred: #fce1e0;
    --primary-home: rgb(172, 135, 80);
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(255, 255, 255);
    background-color: var(--primary-white);
    font-size: large;
   }
   
h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 10;
    text-transform: uppercase;
   }

/****************NAVBAR*******************/

.Link {
  color: black !important;
  text-decoration: none;
  cursor: pointer;
}

.Link:hover {
  text-decoration: none !important;
}


nav a.nav-link {
    padding-top: 0;
    border-top: 0cm;
    font-weight: 200;
    letter-spacing: 0.0375rem;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}


.navbar-nav > li > a {
    color: black;
}

.navbar-brand{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0 !important;
    cursor: pointer;

}



.navbar-nav > li > a.active, .navbar-nav > li > a.active.focus {
    border-bottom: 0.1875rem solid rgb(172, 135, 80) !important;
    border-bottom: 0.1875rem solid var(--primary-home) !important;
}

.navbar-nav > li > a:hover {
    border-bottom: 0.1875rem solid rgb(172, 135, 80);
    border-bottom: 0.1875rem solid var(--primary-home);
    text-decoration: none !important;
    
}

.lang {
  font-size: small;
  font-family: 'Lora', sans-serif;
  text-transform: lowercase !important;
}


/**********HEADER***************/

.header{
  background-image: url(../../static/media/hotelbilde.dcf62b13.jpg)!important;
  background-position: center;
  background-size:cover;
  
}


/*****************CONT*****************/

.productcolor{
  background-color: #eeeeee;
}

.carousel-item {
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.caro1 {
  background-image: url(../../static/media/bed.05d20744.jpeg);
}
.caro2 {
  background-image: url(../../static/media/Hotelind.d5c98803.jpeg);
}
.caro3 {
  background-image: url(../../static/media/project1.6ca421a6.jpeg);
}
.carousel-item h1 {
  margin: 0;
  color: white;
}
.mask{
  background-color: rgba(0, 0, 0, 0.61);
}

.mask1bg{
  
  background-image: url(../../static/media/seepia.35cd0bc3.jpg);
  background-position: center;
  background-size: cover;
}



.mask1{
  background-color: rgba(0, 0, 0, 0.63);
}
.mask2{
  background-color: rgba(0, 0, 0, 0);
}

.card{
  width: 18rem;
}


/**********FOOTER***********/

